import { computed } from 'vue'
import { useApi } from '@tenant/composables'
import { useQuery } from '@tanstack/vue-query'

export function useCloudStorages() {
    const { execute } = useApi('/api/cloud-storages', 'GET')
    const cloudStorages = computed(() => (data.value ? data.value : []))

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['cloud-storages'],
        queryFn: () => execute(),
        staleTime: Infinity,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })

    return { cloudStorages, execute, refetch, isLoading }
}
